import React from "react"
import Header from "../../Header"
import ReadyUppyImg from "../../images/ReadyUppy.png"
import ReadyUppyGif from "../../images/ReadyUppy.gif"
import LexiaImg from "../../images/Lexia.png"
import { Link } from "gatsby";

export default function ReadyUppy() {
    return (
        <>
            <Header noog={true} title="Blog - An Experimental Reading Game using Speech Recognition"
                description="Using speech recognition to build a game to help kids identify sight words and some numbers."
                image={"https://johnhaldeman.ca" + ReadyUppyImg}
            ></Header>
            <p style={{"textAlign": "right"}}>Posted June 26, 2020</p>
            <h3>A Reading Game Using Speech Recognition</h3>
            <p>
                During the COVID-19 pandemic, our kids became remote learners overnight. It was eye opening in a lot of ways,
                one of them being how advanced online resources have become. The online educational tools are both numerous and
                sophisticated.
            </p>
            <p>
                One of these tools is <a href="https://www.lexialearning.com/products/core5">Lexia Core 5</a>. It helps kids learn
                to read and then helps them with their grammar. It's an interesting application. The exercises are varied, and it seems well
                designed to speed up or slow down depending on how strong a child's skills are in a particular area.
            </p>
            <p>
                One interesting thing about applications like Lexia is that the student's skills are all developed without
                the student <i>saying</i> anything. There are all kinds of sound matching activities, word recognition activities, and
                other tasks where, if you were teaching someone face-to-face, you would ask them to vocalize the answer. "Can you say this word"
                you might ask holding up a flashcard with the sight word "the". In Lexia it seems to be done instead by  
                providing a prompt, playing an audio clip of someone saying "the", and then the student selecting 
                the word from a set of options. In effect, it's a kind of multiple choice, instead of an oral response.
            </p>
            <a href={LexiaImg}><img src={LexiaImg} width="50%" className="center"></img></a>
            <p className="img-caption">A warm up exercise in Lexia: "Voiceover: click on R when you see it"</p>
            <p>
                I'm not sure a method of teaching where you ask the student to say the words rather than choose the words 
                has better results, but I decided it might be interesting to build a little game to see what kind of problems,
                if any, arise when you try. That game is Read-y Uppy (a play on words for Keepy Uppy which is a name for the game
                where you try to keep a balloon in the air using your hands without grabbing it). 
            </p>
            <p> <strong style={{color:"#c80000"}}>For reasons I will make apparent later,
                the game only works in Chrome and even then, it doesn't seem to work 
                correctly on mobile (it's a fun experiment, not a 
                production app). 
                </strong> You can play Read-y Uppy here:
            </p>
            <p>
                <a href="https://johnhaldeman.ca/ReadyUppy/">https://johnhaldeman.ca/ReadyUppy/</a> (Chrome for Desktop required)
            </p>
            <a href={ReadyUppyGif}><img src={ReadyUppyGif} width="30%" className="center"></img></a>
            <p className="img-caption">Animated GIF of Read-y Uppy. The balloon bounces when the correct word is said by the user
                 (click/tap for larger image)</p>
            <p>
                The results were mixed. The kids were very enthusiastic about playing the game. Games 
                are extremely effective tools for motivating kids to learn. My son for instance loves
                to play Lexia mostly, I think, because it makes him feel like he's progressing - unlocking
                new areas, new tasks, filling in his circles, etc. The feedback is immediate, and progress
                is visualized. The task itself isn't as fun as many other activities, but he'll do anything if there's stuff to unlock and
                click and experiment with. He asks often if he can do some Lexia. The kids also immediately
                recognized the game and what they needed to do - so that was a win.
            </p>
            <p> 
                Unfortunately, while speech recognition is becoming much improved, it is still not perfect.
                The main problems I had were with accuracy and responsiveness. My kids, even when saying the correct word,
                were often misunderstood the first time. It reminded me of what it is like talking to 
                most cars for hands-free calling. It's not the kind of speed and accuracy you want for a time-pressured 
                game like keeping a balloon in the air.
            </p>
            <p> When using virtual assistants and voice search, I think results are better, but it could be because 
                they rely on full sentences which may allow for more accuracy. Some of the errors can be corrected 
                based on context with other words used in the command. I tried to tune for maximum
                forgiveness, but an interesting thing happens when you tell the library to generate more alternatives: it seems
                to take longer to get results, decreasing responsiveness. There may be a tradeoff here between
                speed and accuracy. All of this is speculation though as I am no expert in the area and results 
                may be improved with tuning or using a different library or a cloud service. If you'd like to try, 
                the code is as simple as it gets (I hope) so feel free to give it a go.
            </p>
            <p>
                The other problem was with browser compatibility. I used the Web Speech API as 
                described <a href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API">here</a>. 
                Chrome is the only browser that supports it, although Mozilla appears to have 
                one in development for Firefox. It will be interesting to try this again when Firefox releases their API as 
                the browsers often leap-frog one another when it comes to improvements to functions. If someone was motivated
                you could use an external service for the speech recognition instead of relying
                on what the browser supports to get cross-browser support.
            </p>
            <p>
                All-in-all this was a fun little project where the results were encouraging, but not
                quite good enough to create a serviceable game. Lexia doesn't use speech recognition and instead
                relies on input via mouse and keyboard only. I can understand why.
            </p>
            

            <p style={{textAlign: "center"}}>
                <Link to="/posts/beasts-adventure"><button>Previous Post</button></Link>&nbsp;
            </p>
        </>
    )
}